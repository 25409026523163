import React, { useState, useEffect, useContext } from 'react'
import { GlobalStyles } from './src/styles/global'
import { GlobalStyles as TailwindStyles } from 'twin.macro'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
// import Helmet from 'react-helmet'
import { useLocation, useMount } from 'react-use'
import { motion } from 'framer-motion'
import { use100vh } from 'react-div-100vh'

import { MobileMenu } from './src/components'

import { MobileMenuProvider, MobileMenuContext } from './src/components/MobileMenu/context'
import { ThemeProvider, ThemeContext } from './src/context/ThemeContext'
import { PageTitleProvider } from './src/context/PageTitleContext'
import { getGlobalData } from './src/utils'

import { RemoveScrollBar } from 'react-remove-scroll-bar'

const App = ({ element }) => {
    const { global: data } = getGlobalData()
    const location = useLocation()
    // const screenHeight = use100vh()
    const [showContent, setShowContent] = useState(false)
    const theme = useContext(ThemeContext)
    const mobileMenu = useContext(MobileMenuContext)
    const invertMenuTheme = mobileMenu.invertMenuTheme

    const resolveTheme = () => {
        if (!location?.pathname) return

        if (location?.pathname?.includes('projects')) {
            if (theme?.theme !== 'grey') {
                theme?.setTheme('grey')
            }
        } else if (location?.pathname?.includes('news')) {
            if (theme?.theme !== 'light') {
                theme?.setTheme('light')
            }
        } else {
            if (theme?.theme !== 'dark') {
                theme?.setTheme('dark')
            }
        }
    }

    useEffect(() => {
        resolveTheme()
        let timer = setTimeout(() => setShowContent(true), 800)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(() => {
        resolveTheme()
    }, [location?.pathname])

    const outerWrapperVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1
        }
    }

    const innerWrapperVariants = {
        dark: {
            backgroundColor: 'rgb(0,0,0)',
            color: 'rgb(255,255,255)'
        },
        light: {
            backgroundColor: 'rgb(255,255,255)',
            color: 'rgb(0,0,0)'
        },
        grey: {
            backgroundColor: 'rgb(224,224,224)',
            color: 'rgb(0,0,0)'
        }
    }

    const mobileMenuVariants = {
        hidden: {
            opacity: 0,
            backdropFilter: 'blur(0px)',
            transition: {
                duration: 0
            }
        },
        visible: {
            opacity: 1,
            backdropFilter: 'blur(10px)'
        }
    }

    return (
        <OuterWrapper
            initial={'hidden'}
            animate={showContent && 'visible'}
            variants={outerWrapperVariants}
        >
            {/* Mobile Menu */}
            <MobileMenuWrapper
                initial={'hidden'}
                animate={mobileMenu.active ? 'visible' : 'hidden' }
                variants={mobileMenuVariants}
                style={{
                    pointerEvents: !mobileMenu.active && 'none',
                    background: invertMenuTheme ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)'
                }}
            >
                {mobileMenu.active && (
                    <RemoveScrollBar />
                )}
                <MobileMenu
                    menu={data?.nav_links}
                    media={data?.nav_media}
                    mobileCTALink={data?.mobile_cta_link}
                />
            </MobileMenuWrapper>

            <InnerWrapper
                animate={theme?.theme}
                variants={innerWrapperVariants}
            >
                {element}
            </InnerWrapper>
        </OuterWrapper>
    )
}

const OuterWrapper = styled(motion.div)``

const InnerWrapper = styled(motion.div)`
    min-height: 100vh;
`

// Mobile Menu

const MobileMenuWrapper = styled(motion.div)`
    ${tw`z-30 fixed inset-0 w-full`};
    cursor: pointer!important;
`

export default ({ element, props }) => {
    return (
        <ThemeProvider>
            <MobileMenuProvider>
                <PageTitleProvider>
                    <TailwindStyles />
                    <GlobalStyles />
                    <App element={element} />
                </PageTitleProvider>
            </MobileMenuProvider>
        </ThemeProvider>
    )
}