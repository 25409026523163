import React, { useState, createContext } from 'react'
import { useToggle } from 'react-use'

export const MobileMenuContext = createContext()
import { getGlobalData } from '../../utils'

const MobileMenuProvider = ({ children }) => {
    const { global: data } = getGlobalData()
    const { nav_invert_menu } = data
    const [active, toggleActive] = useToggle(false)
    const [invertMenuTheme, setInvertMenuTheme] = useState(nav_invert_menu || false)

    return (
        <MobileMenuContext.Provider
            value={{
                active: active,
                toggleActive: toggleActive,
                invertMenuTheme: invertMenuTheme,
            }}
        >
            {children}
        </MobileMenuContext.Provider>
    )
}

export default MobileMenuContext

export { MobileMenuProvider }