import React, { useState, useContext } from 'react'
import { Link } from 'gatsby'
import ProgressiveImage from 'react-progressive-image'

import { motion } from 'framer-motion'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'
import { getGlobalData } from '../../utils'

import { SignupForm } from '../../components'
import { LogoSVG } from '../../components/Header/LogoSVG'
import { type, padding, container, hoverState, textDot } from '../../styles/global'
import { media } from '../../styles/utils'
import { renderLink } from '../../utils'

import { ThemeContext } from '../../context/ThemeContext'

const Footer = (props) => {
    const { global: data } = getGlobalData()
    const theme = useContext(ThemeContext)
    const { isProject } = props

    const renderContactInfo = () => {
        return (
            <Info>
                <Grid>
                    <Left>
                        <Subheading
                            className={'text-dot'}
                        >
                            Contact Us
                        </Subheading>
                    </Left>
                    {data.contact_email && (
                        <Right>
                            <Item>
                                <a href={`mailto:${data.contact_email}`}>
                                    {data.contact_email}
                                </a>
                            </Item>
                        </Right>
                    )}
                </Grid>
            </Info>
        )
    }

    const renderLocationInfo = () => {
        const { locations } = data
        if (!locations || locations.length === 0) return

        const items = locations.map((item, index) => {
            const { location, address, phone_number } = item
            return (
                <Item
                    key={index}
                >
                    {location && (
                        <div>
                            {`${location} Location`}
                        </div>
                    )}
                    {phone_number && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: phone_number,
                            }}
                        />
                    )}
                    {address && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: address,
                            }}
                        />
                    )}
                </Item>
            )
        })

        return (
            <Info>
                <LocationsGrid>
                    {items}
                </LocationsGrid>
            </Info>
        )
    }

    const renderSocialLinks = () => {
        return (
            <SocialLinks>
                {data.instagram_link && (
                    <Item>
                        <ExternalLink
                            href={data.instagram_link}
                            target={'_blank'}
                        >
                            Instagram
                        </ExternalLink>
                    </Item>
                )}
                {data.linkedin_link && (
                    <Item>
                        <ExternalLink
                            href={data.linkedin_link}
                            target={'_blank'}
                        >
                            LinkedIn
                        </ExternalLink>
                    </Item>
                )}
                {data.facebook_link && (
                    <Item>
                        <ExternalLink
                            href={data.facebook_link}
                            target={'_blank'}
                        >
                            Facebook
                        </ExternalLink>
                    </Item>
                )}
            </SocialLinks>
        )
    }

    const resolveTheme = () => {
        switch (theme?.theme) {
            case 'dark':
                return {
                    fill: 'rgb(255,255,255)',
                }
                break
            case 'light':
                return {
                    fill: 'rgb(0,0,0)',
                }
                break
            case 'grey':
                return {
                    fill: 'rgb(0,0,0)',
                }
                break

            default:
                break
        }
    }

    const renderLogo = () => {
        return (
            <LogoWrapper style={resolveTheme()}>
                <LogoSVG />
            </LogoWrapper>
        )
    }

    return (
        <Wrapper id="footer-section" isProject={isProject}>
            <Container>
                <Top
                    style={resolveTheme()}
                >
                    <Grid>
                        <Left>{renderLogo()}</Left>
                        <Right>
                            {renderContactInfo()}
                            {renderLocationInfo()}
                        </Right>
                    </Grid>
                </Top>
                <Middle
                    style={resolveTheme()}
                >
                    <Grid>
                        <Left>
                            <Heading>Sign up to our Newsletter</Heading>
                        </Left>
                        <Right>
                            <FormWrapper>
                                <SignupForm />
                            </FormWrapper>
                        </Right>
                    </Grid>
                </Middle>
                <Bottom
                    style={resolveTheme()}
                >
                    <Grid>
                        <Left>
                            {data.footer_small_text && (
                                <SmallText
                                    dangerouslySetInnerHTML={{
                                        __html: data.footer_small_text,
                                    }}
                                />
                            )}
                        </Left>
                        <Right>{renderSocialLinks()}</Right>
                    </Grid>
                </Bottom>
            </Container>
        </Wrapper>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Item = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`relative`};
    padding-bottom: 3rem;
    z-index: 1;
    margin-top: -1px;

    ${props => {
        if (props.isProject)
            return css`
                background: rgb(224, 224, 224);
            `
    }}

    ${media.phone`
        ${tw`border-t border-darkGrey`};
    `}
`

const Container = styled.div`
    ${container};
    ${padding};
`

// Grid

const Left = styled.div``
const Right = styled.div``
const Grid = styled.div`
    ${tw`flex w-full`};

    ${media.phone`
        ${tw`flex-col`};
    `}

    > *:first-child,
    > *:last-child {
        ${tw`w-1/2`};

        ${media.phone`
            ${tw`w-full`};
            padding: 0!important;
        `}
    }

    > *:first-child {
        padding-right: 1.5rem;

        ${media.phone`
            margin-bottom: 3rem;
        `}
    }

    > *:last-child {
        padding-left: 1.5rem;
    }
`

// Location Grid
const LocationsGrid = styled.div`
    ${tw`grid grid-cols-2 gap-x-12 gap-y-8`};

    ${media.phone`
        ${tw`grid-cols-1 gap-y-12`};
    `}
`

// --- Top

const Top = styled.div`
    ${tw`border-t border-b border-darkGrey`};
    padding: 3rem 0;

    ${media.phone`
        border-top: none;
    `}
`

// Logo

const LogoWrapper = styled(motion.div)``

// Contact Info (address, phone, email)

const Info = styled.div`
    * {
        ${type.body}
        line-height: 1.5;
    }

    .text-dot {
        ${textDot};
    }

    &:last-child {
        margin-top: 3rem;
    }
`

const bottomSections = css`
    ${tw`border-t border-b border-darkGrey`};
    margin-top: -1px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;

    ${media.phone`
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    `}
`

// --- Middle

const Middle = styled.div`
    ${bottomSections}
`

// Signup Form

const FormWrapper = styled.div`
    width: 100%;
`

// --- Bottom

const Bottom = styled.div`
    ${bottomSections}
`

// Social Links

const SocialLinks = styled.div`
    ${tw`flex`};

    ${Item} {
        ${tw`w-1/3`};
    }

    ${ExternalLink} {
        font-size: 0.875rem;
        line-height: 1.5;

        ${media.phone`
            font-size: 1.2rem;
        `}
    }
`

// Small Text

const SmallText = styled.div`
    font-size: 0.875rem;
    line-height: 1.5;

    p:not(:last-child) {
        margin-bottom: 1em;
    }
`

export default Footer
